.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-card {
  background: black;
  z-index: 50;
  width: 50px;
  height: 70px;
  transform: skew(-14deg);
  position: absolute;
  border-radius: 5px;
  background-size: 140%;
  box-shadow: 0px 2px 0px white, 0px -2px 0px white;
}

.App-card:hover {
  cursor: pointer;
  background: black;
  width: 6vh;
  height: 8vh;
  transform: skew(-14deg);
  position: absolute;
  border-radius: 5px;
  background-size: 140%;
  box-shadow: 0px 8px 5px rgb(255, 255, 176), 0px -8px 5px rgb(255, 255, 176), -5px 0px 5px rgb(255, 255, 176), 5px 0px 5px rgb(255, 255, 176);
}

.Footer {
  margin-top: 83vh;
  width: 100%;
  height: 110px;
  z-index: 1;
  position: absolute;
  backdrop-filter: blur(10px);
  margin-bottom: 0px;
  border-bottom: 2px solid orange;
  border-top: 2px solid orange;
}

.TitleCard {
  font-family: Overwatch, Arial, Helvetica, sans-serif;
  color: white;
  text-align: center;
  width: 100%;
  z-index: 1;
  margin-top: 1vh;
  position: absolute;
  margin-top: 93.5vh;
}

.Panel {
  position: absolute;
  box-shadow: 0px 8px 5px rgb(255, 125, 19), 0px -8px 5px rgb(255, 125, 19), -5px 0px 5px rgb(255, 125, 19), 5px 0px 5px rgb(255, 125, 19);
  border-radius: 3%;
  border: 5px solid black;
  left: 50%;
  top: 45vh;
  z-index: 1;
  transform: translate(-50%,-50%);
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
